html,
body {
  height: 100% !important;
  margin: 0;
  padding: 0;
  margin: auto;
  transition: all 0.5s ease !important;
  width: 100%;
  height: 100%;
  background-color: white;
  scroll-behavior: smooth;
}

.Home {
  background-image: url("../public/uuundulate.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 70px;
  padding-bottom: 70px;
  max-width: 1500px !important;
  text-align: center;
}

.hero {
  padding: 40px !important;
  height: 100%;
}

.hero.right {
  filter: blur(10px);
  opacity: 0;
  animation: blurToClear 0.8s ease-out 0.6s forwards;
}

.service {
  position: relative;
  border-radius: 40px;
  padding: 5px 10px;
  border: 1.5px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #9198e5, #e66465);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  width: 250px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.service.ai {
  width: 200px;
}

.service.jpg,
.service.png {
  width: 300px !important;
}

.service:hover,
.choice:hover {
  transform: translateY(-3px);
}

.icon {
  position: absolute;
  left: 15px;
  width: 22px;
  margin-right: 20px;
}

.icon.doc {
  position: absolute;
  left: 15px;
  width: 22px;
  height: 25px;
  margin-right: 20px;
}

.hero h1 {
  filter: blur(10px);
  opacity: 0;
  animation: blurToClear 0.8s ease-out forwards;
}

.hero h1:nth-child(2) {
  animation-delay: 0.2s;
}

.hero h1:nth-child(3) {
  animation-delay: 0.4s;
}

.hero-des {
  width: 80%;
  filter: blur(10px);
  opacity: 0;
  animation: blurToClear 0.8s ease-out 0.6s forwards;
}

.try-btn {
  padding: 8px 25px;
  border-radius: 40px;
  border: 3px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #9198e5, #e66465);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  filter: blur(10px);
  opacity: 0;
  animation: blurToClear 0.8s ease-out 0.6s forwards;
}

.try-btn:hover {
  transform: translateY(-3px) !important;
  background-image: linear-gradient(to right, #9198e5, #e66465);
  color: white;
  transform: scale(1.05);
}

.try-btn.chat {
  padding: 5px 20px;
  margin: 5px;
  cursor: pointer;
}

@keyframes blurToClear {
  0% {
    filter: blur(10px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.tool-container {
  width: 120px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  margin-bottom: 10px;
  border-left: 2px solid transparent;
  border-bottom: 1.5px solid transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #9198e5, #e66465);

  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.tool-container {
  border: 1px solid lightgrey;
}

h1,
h2,
h3,
h4,
span,
a,
button {
  position: relative;
  z-index: 4;
  color: rgb(34, 34, 34);
  font-weight: bold !important;
}

p,
.row-content-text {
  margin: 0px !important;
  color: rgb(77, 77, 77) !important;
}

.row-content-text {
  font-size: 0.9rem !important;
}

.row-title-text {
  font-weight: 500;
}

.container {
  max-width: 1500px !important;
}

.navbar {
  position: fixed !important;
  top: 10px !important;
  left: 50%;
  transform: translateX(-50%);
  width: 95% !important;
  padding: 20px 0px;
  padding: 10px 20px !important;
  background-color: rgba(255, 255, 255, 0.95) !important;
  transition: all 0.8s ease;
  z-index: 4000;
  border-radius: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-width: 1500px !important;
}

.navbar.scrolled {
  width: 30% !important;
}

.Home,
.JPG,
.PNG,
.PDF,
.RM,
.Image {
  width: 100%;
  margin: 100px auto;
  transition: all 0.5s ease;
}

.Smartify {
  width: 100%;
}

.SignPdf {
  margin-top: 100px !important;
}

.nav-link {
  text-align: center !important;
  margin: auto;
}

.dropdown-menu {
  z-index: 4000 !important;
  text-align: center !important;
}

.dropdown-item {
  text-align: center !important;
}

.service-text.night {
  color: white !important;
}

.table-container > h2 {
  text-align: center;
}

.input-container {
  margin-top: 40px;
  height: 75vh;
  position: relative;
  z-index: 5;
  background-color: transparent;
  transition: all ease 0.5s;
  cursor: pointer;
}

.input-container.eighty {
  margin-top: 40px !important;
  height: 75vh;
  position: relative;
  z-index: 5;
  background-color: transparent;
  transition: all ease 0.5s;
}

.input-container.sixty {
  margin-top: 40px !important;
  height: 55vh;
  position: relative;
  z-index: 5;
  background-color: transparent;
  transition: all ease 0.5s;
}

.input-container.drag-over,
.input-container:hover {
  border: 3px dashed #e66465 !important;
  background-color: rgba(88, 88, 88, 0.6) !important;
  cursor: pointer;
}

.original-theme {
  width: 35px;
  height: 35px;
  background: linear-gradient(#e66465, #9198e5);
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.night-theme {
  width: 35px;
  height: 35px;
  --dot-bg: #161616;
  --dot-color: rgb(50, 199, 50);
  --dot-size: 1px;
  --dot-space: 10px;

  background: linear-gradient(
        90deg,
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    linear-gradient(
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    var(--dot-color);
  border-radius: 10px;
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.pro-theme {
  width: 35px;
  height: 35px;
  background-image: url("../public/oooscillate.svg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  background-color: rgb(232, 232, 232);
  cursor: pointer;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
}

.main {
  background-color: rgb(49, 49, 49);
}

.SmartJPG {
  margin: 0px;
  font-size: 3.5rem;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  transition: all 0.3s ease;
}

h3 {
  font-weight: bold !important;
  color: rgb(59, 59, 59) !important;
}

.gradient-border {
  position: relative;
  border: 2px solid transparent;
  border-radius: 50px;
  background-image: linear-gradient(#e66465, #9198e5);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  padding: 5px 10px;
}

.convert-btn,
.swap,
.back {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px;
  padding: 10px 10px;
  border: none;
  background-color: rgba(232, 232, 237, 0.7);
  color: rgb(29, 29, 31);
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.08);
  transition: all 0.1s ease;
}

.back {
  width: 70%;
}

.back:hover {
  transform: translateY(-3px);
}

.swap-btns {
  justify-content: space-between;
}

.swap {
  position: relative;
  font-size: 0.9rem;
  z-index: 5;
  margin: 0px;
  text-align: center;
}

.swap.save,
.swap.clear {
  margin: 5px;
  margin-top: 20px;
}

.resize {
  width: 100%;
  height: 100%;
}

.resize-container {
  margin-top: 20px;
}

.nav-item {
  border: none !important;
  margin: 5px;
}

.coffee {
  margin: auto;
  text-align: center;
}

.swap.current {
  background-color: rgba(0, 113, 227, 1);
  color: white !important;
}

.back {
  margin-top: 100px;
}

.icon-control {
  background-color: rgb(0, 113, 227);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 15px;
}

.arrow-container {
  position: absolute;
  right: 10px;
  top: 0px;
}

.arrow-right {
  width: 35px;
}

.arrow-right:hover {
  border-radius: 0px;
  box-shadow: none;
  transform: translateY(0px);
}

.icon-control:hover {
  transform: translateY(0px);
  box-shadow: none;
}

.convert-btn:hover {
  transform: translateY(-3px);
}

.download-btn {
  width: 100% !important;
  border-radius: 40px;
  padding: 10px 35px;
  border: none;
  background-image: linear-gradient(#e66465, #9198e5);
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.15s ease;
  text-decoration: none !important;
  color: white !important;
}

.input {
  width: 100%;
}

svg {
  border-radius: 22px;
  transition: all 0.15s ease;
}

.navbar-brand {
  font-size: 1.5rem !important;
  line-height: 2rem;
  font-weight: 800;
  background-image: linear-gradient(to right, #9198e5, #e66465) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

.smartify {
  transition: all 0.3s ease;
}

.title {
  height: 5vh;
  background-color: rgb(49, 49, 49);
  padding-left: 10px;
  transition: all 0.5s ease;
}

.title.white {
  background-color: white !important;
}

.smartify-text {
  font-size: 1.3rem;
  font-weight: 600;
  background-image: linear-gradient(to right, #9198e5, #e66465) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

.smartify-text.brand-name {
  font-size: 1.2rem !important;
}

.generating {
  font-size: 0.9rem !important;
  text-align: center;
  color: white !important;
}

.generating.white {
  color: rgb(77, 77, 77) !important;
}

.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.w-full {
  width: 100% !important;
}

.slogan {
  width: 80%;
  margin: auto;
  padding: 15px;
}

.slogan-item {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.illustion-des > div {
  padding: 0px 15px;
}

.trend {
  margin: auto;
  text-align: left;
}

.trend > h2 {
  width: 80%;
  margin: auto;
}

.second {
  border-top: 1px solid #9198e5;
  border-bottom: 1px solid #e66465;
}
.graphic {
  position: relative;
  transition: all 0.3s ease !important;
}

.left,
.right {
  transition: opacity 0.3s ease, visibility 0.3s ease !important;
  opacity: 1;
  visibility: visible;
}

.graphic:hover .left,
.graphic:hover .right {
  opacity: 0;
  visibility: hidden;
}

.graphic:hover .smartify-ai-graphic {
  width: 100px;
  height: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.smartify-ai-graphic-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.smartify-ai-graphic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.square {
  height: 100px;
  display: flex;
  align-items: center;
}

.square.left {
  justify-content: flex-start;
}

.square.right {
  justify-content: flex-end;
}

.one {
  background-color: #9198e5;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(230, 100, 101, 0.9);
  padding: 10px;
  text-align: center;
  opacity: 0.8;
}

.one-text {
  color: white !important;
  font-size: 15px;
  font-weight: 500;
}

.four {
  background-color: #e66465;
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -4px -4px 8px rgba(145, 152, 229, 0.9);
  padding: 10px;
  text-align: center;
}

.highlight-text-container {
  position: relative;
}

.highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1.6rem;
  background-color: rgba(189, 186, 20, 0.4);
  border-top-left-radius: 10px;
  border-bottom-right-radius: 50px;
  transition: all 0.3s ease;
}

.highlight.one {
  width: 25%;
}

.highlight.two {
  width: 50%;
}

.highlight.three {
  width: 75%;
}

.highlight.four {
  width: 100%;
}

.highlight-text-container {
  position: relative;
  font-size: 35px;
}

footer {
  width: 100%;
  font-size: 0.875rem;
  margin: auto;
}

.copyright {
  padding: 10px;
  margin: auto;
}

.github-icon {
  font-size: 20px;
  text-decoration: none !important;
  color: rgb(56, 56, 56) !important;
  margin: 10px;
}

.info-box {
  width: 80%;
  padding: 10px;
  margin: auto !important;
}

.info-box > div {
  cursor: pointer;
}

.about-us {
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100%;
  margin: 150px auto 40px auto;
}

.check-here-div {
  position: absolute;
  top: 5%;
  right: 15%;
  z-index: 10;
}

.arrow {
  width: "150px";
  transform: scaleX(-1);
}

.check-here {
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
  font-size: 30px;
}

.underline {
  height: 13px;
  width: 30%;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="10" viewBox="0 0 100 10"><path fill="none" stroke="gray" stroke-width="2" d="M0 5 Q 25 0 50 5 T 100 5"/></svg>')
    repeat-x;
  background-size: cover;
  margin-right: 70px;
  margin-left: auto;
  transform: rotate(-5deg);
  transform-origin: left;
  margin-bottom: -7px;
}

.drag-and-drop-text {
  font-size: 30px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 5px;
}

.line-container {
  position: relative;
}

.line-background {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.service-text {
  margin-top: 10px;
  font-weight: 600;
}

.about-link {
  text-decoration: none;
  margin: 10px;
}

.layer {
  background-image: url("../public/uuundulate.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.Privacy,
.Terms,
.About {
  max-height: 500px;
  overflow-y: auto;
  padding: 30px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  margin: 100px auto 40px auto;
}

.pdf-file {
  text-align: center !important;
}

.ai-icon {
  display: flex;
  justify-content: center;
}

.ai-icon > img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 50%;
}

.input-ai {
  width: 100%;
  padding: 10px 20px;
  border-radius: 40px;
  border: 1px solid grey;
  margin-bottom: 10px;
}

.ai-text {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 10px;
  display: block;
}

.file-name-input {
  width: 60%;
  margin: 15px;
  padding: 5px 15px;
  border-radius: 40px;
  border: 1px solid grey;
}

.SignPdf {
  z-index: 2000;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: all 0.5s ease;
  margin: 40px auto;
}

.react-pdf__Page {
  position: relative;
}

.react-pdf__Page__textLayer,
.react-pdf__Page__annotationLayer {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1);
  transform-origin: top left;
  z-index: 2;
  pointer-events: none;
}

.react-pdf__Page__canvas {
  z-index: 1;
}

.row {
  width: 100% !important;
  margin: 0px !important;
}

.col-12,
.col-6,
.col-9,
.col-8,
.col-9,
.col-10,
.col-4 {
  padding: 0px !important;
  width: 100%;
}

.col-12.row.smartify {
  max-width: 1500px;
  margin: auto !important;
  position: relative;
}

.col-2 {
  overflow-y: auto;
  height: 100vh;
  padding: 10px !important;
  background-color: rgb(49, 49, 49);
  border-left: 1px solid rgb(77, 77, 77);
}

.col-2.white {
  background-color: #e9eef6;
  border-color: white;
}

.signature-container {
  margin: auto;
  width: 95%;
  max-width: 100%;
  position: relative;
  padding: 15px;
  background-color: white;
}

.ai-image {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Description {
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.row.des {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: auto;
}

.step {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 0 1 300px;
}

.step img {
  width: 50px;
  margin-bottom: 20px;
}

.seperate {
  height: 6px;
  width: 250px;
  background-color: grey;
  margin: 50px auto;
  border-radius: 40px;
}

.step h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  line-height: 1.5;
}

.react-pdf__Page {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.line-background-des {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10% 20%;
  text-align: center;
  margin: auto;
}

.footer {
  position: relative;
}

.square-container {
  position: absolute;
  right: 0px;
}

.square-container > img {
  width: 280px;
}

.row.slogan {
  display: flex;
  justify-content: center;
  align-items: center;
}

table {
  margin: auto;
  width: 90%;
  text-align: left;
  border-collapse: separate !important;
  border-spacing: 0;
  border-radius: 20px !important;
}

th,
td {
  border: 1px solid lightgrey !important;
  padding: 10px;
}

th {
  background-color: lightgrey;
  color: white;
}

.contact-btn {
  width: 40%;
  background-color: rgb(28, 160, 28);
  border: none !important;
  padding: 10px 16px;
  border-radius: 40px;
  margin-top: 40px;
  text-decoration: none !important;
  color: white !important;
  width: 60% !important;
  transition: all 0.3s ease;
  cursor: pointer;
}

.contact-btn.smartify {
  margin-top: 0px !important;
}

.contact-btn:hover {
  transform: translateY(-3px);
  background-color: rgb(23, 138, 23);
}
.question-section {
  position: relative;
  background-image: linear-gradient(to right, #9198e5, #e66465);
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.question-section::before,
.question-section::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 1;
  pointer-events: none;
}

.question-section::before {
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}

.question-container {
  width: 60%;
  margin: auto;
  text-align: center;
  position: relative; /* Ensure content is above the fade */
  z-index: 2; /* Ensure content is above the fades */
}

.Chat {
  height: 95vh;
  overflow-y: auto;
  background-color: rgb(49, 49, 49);
  transition: all 0.5s ease !important;
  padding: 0;
  margin: 0;
  transition: all 0.5s ease !important;
}

.Chat.white {
  background-color: white !important;
}

.theme {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 35px !important;
  height: 35px !important;
  padding: 0px !important;
}

.theme-btn {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.chat-content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-content > div {
  width: 100%;
}

.heading {
  padding-bottom: 5px;
  text-align: left;
}

.eighty,
.sixty,
.full {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full {
  height: 95vh;
}

.eighty {
  height: 75vh;
}

.sixty {
  height: 55vh;
}

.fourty {
  height: 40vh;
  padding: 16px;
  padding-bottom: 0px;
}

.twenty {
  height: 20vh;
  padding-top: 0px;
  transition: all 0.5s ease !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-md-6 {
  margin: 10px 0px;
}

.greeting {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 20px;
}

.smartify-text {
  font-size: 2rem !important;
  text-align: center;
}

.des {
  color: white !important;
  margin-top: 20px;
}

.model-name {
  font-size: 1.1rem;
  font-weight: normal !important;
  padding-top: 20px;
  color: white;
}

.model-name.white {
  color: rgb(77, 77, 77) !important;
}

::placeholder {
  background-image: linear-gradient(to right, #9198e5, #e66465) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.input-field {
  position: relative;
  background-color: rgb(100, 100, 100);
  border-radius: 40px;
  margin: auto;
  width: 60%;
  padding: 15px 20px;
  padding-right: 50px;
  padding-left: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: popup-animation 0.5s ease-out;
  transition: all 0.5s ease !important;
}

.input-field.white {
  background-color: #e9eef6 !important;
}

.input-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0px !important;
  background-color: rgb(100, 100, 100);
  color: white;
  outline: none;
  transition: all 0.5s ease !important;
}

.input-area.white {
  background-color: #e9eef6 !important;
  color: rgb(77, 77, 77) !important;
}

.chat-block {
  display: block;
  padding: 10px;
  animation: popup-animation 0.5s ease-out;
}

.preview {
  width: 100%;
}

.preview-image {
  animation: popup-animation 0.5s ease-out;
}
.preview-text {
  font-size: 0.8rem;
  color: white !important;
}

.preview-text.white {
  color: rgb(77, 77, 77) !important;
}

@keyframes popup-animation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-container.ai {
  display: flex;
  justify-content: flex-start;
}

.chat-container.user {
  display: flex;
  justify-content: flex-end;
}

.chat {
  width: auto;
  height: auto;
  background-color: rgb(79, 79, 79);
  border-radius: 20px;
  padding: 15px 20px;
}

.chat.white {
  background-color: #e9eef6;
}

.chat.ai {
  border-top-left-radius: 5px;
}

.chat.user {
  border-top-right-radius: 5px;
}

.choice {
  color: white;
  padding: 6px 20px;
  margin: 10px;
  border-radius: 40px;
  border: 1px solid rgb(173, 171, 171);
  cursor: pointer;
  transition: all 0.3s ease;
}

.choice.white {
  color: rgb(77, 77, 77) !important;
}

.text-length {
  display: inline-block;
  width: fit-content;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  color: white !important;
  word-wrap: break-word;
  white-space: normal;
  text-align: left;
}

.text-length.white {
  color: rgb(77, 77, 77) !important;
}

.send-btn {
  position: absolute;
  background-color: white;
  border: 0px;
  transition: all 0.3s ease;
  border-radius: 50%;
  width: 35px;
  text-align: center;
  padding: 5px 5px;
  cursor: pointer;
  right: 10px;
  top: 10px;
}

.send-btn:hover {
  background-color: rgb(204, 204, 204);
}

.choices {
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.show-input {
  background-color: transparent;
  font-weight: normal !important;
  border: 0px;
  font-size: 17px;
}

.show-input.smartify-text {
  font-size: 1.1rem !important;
}

.question {
  font-size: 1rem !important;
  margin-top: 20px;
}

.preview.mobile {
  display: inline-flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  white-space: nowrap;
  display: none;
}

.preview.mobile > div {
  flex: 0 0 auto;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.preview-image.mobile {
  width: 80%;
  height: auto;
  margin: 0 !important;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin-top: 5px !important;
}

.reminder {
  color: white !important;
  display: none;
}

.disclaimer {
  position: relative;
  width: 60%;
  margin: 16px auto;
  padding-top: 4px;
  padding-bottom: 20px;
  border-radius: 20px;
  background-color: #252728;
  z-index: 1000;
}

.disclaimer.white {
  background-color: #e9eef6 !important;
}

.disclaimer-text {
  color: white !important;
  margin: 20px 24px;
  width: 85%;
}

.disclaimer-text.white {
  color: rgb(77, 77, 77) !important;
}

.disclaimer > p {
  color: white !important;
  margin: 0px 24px !important;
  font-size: 0.8rem !important;
}

.hide {
  position: absolute;
  right: 20px;
  top: 15px;
  border-radius: 50px;
  padding: 5px 9px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.hide:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (max-width: 991px) {
  .square-container {
    display: none;
  }
  .slogan {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .eighty,
  .sixty,
  .full {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .donate-container {
    max-width: 100% !important;
    right: 0px !important;
    top: 0px !important;
    height: 110px;
    padding: 10px !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .gradient-border {
    right: 0px !important;
    top: 0px !important;
  }
  .donate-des {
    display: none;
  }
  .check-here-div {
    display: none;
  }
  .container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .nav-item.download {
    display: none;
  }
  .input-container.ai-image {
    max-width: 100% !important;
  }
  .dropdown-item.sign {
    display: none;
  }
  .input-container {
    width: 100% !important;
  }
  .navbar.scrolled {
    width: 60% !important;
  }
  .question-container {
    width: 90%;
  }
  .col-12.col-md-6.text-left {
    text-align: center !important;
  }
  .hero-des {
    width: 100%;
  }
  .col-2 {
    display: none;
  }
  .col-10 {
    width: 100% !important;
  }
  .preview.mobile {
    display: block;
  }
  .input-field {
    width: 80%;
  }
  .disclaimer {
    width: 100%;
  }
  .theme {
    left: 10px;
    bottom: 10px;
  }
}
